<template>
  <div class="Banner">
    <div class="Wrapper">
      <a :href="link"
         target="_blank"
         class="Banner-Link"
      >
        <div class="Banner-Info">
          <div
              v-if="$screen.width > 1247"
              class="Banner-Label"
          >
            Новое интервью
          </div>
          <div class="Banner-Content">
            <div class="Banner-ContentTitle">
              Интервью с СЕО Syndicate Group: «В рекордный день сделали 2 500 депозитов в гемблинге»
            </div>
            <div  v-if="$screen.width > 959"
                class="Banner-ContentText">

            </div>
          </div>
        </div>
        <a :href="link"
           target="_blank"
           class="Banner-InfoLink">
            <span class="Banner-InfoLinkText">
             читать интервью
            </span>
<!--          <div class="Button-Shadow"></div>-->
        </a>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      link: "https://partnerkin.com/blog/intervyu/interview_syndicate"
    }
  },
}
</script>

<style lang="scss">
.Banner {
  width: 100%;
  background-color: var(--color-banner-bg);

  &-Link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9.5px 0;

    @media (min-width: $screen-l) {
      padding: 18px 0;
    }
  }

  &-Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Label {
    margin-right: 20px;
    padding: 11px 33px;
    background-color: var(--color-banner-label);
    font-size: 18px;
    text-transform: uppercase;
    color: var(--color-banner-bg);
    white-space: nowrap;
  }

  &-Content {
    margin-right: 11px;
    color: var(--color-banner-content);

    @media (min-width: $screen-l) {
      margin-right: 0;
    }
  }

  &-ContentTitle {
    font-size: 10px;
    line-height: 1.23;
    text-transform: uppercase;
    @media (min-width: $screen-l) {
      font-size: 18px;
      line-height: 1.58;
    }
  }

  &-ContentText {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
  }

  &-InfoLink {
    padding: 8px 11px;
    border: 2px solid var(--color-text-main2);
    background: var(--color-bg-button);
    font-size: 10px;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: $screen-l) {
      padding: 14.5px 45px;
      border: 3px solid var(--color-text-main2);
      font-size: 16px;
    }

    @media (min-width: $screen-xl) {
      padding: 14.5px 49px;
    }
  }
}
</style>