<template>
  <div id="menu" class="MainMenu" :class="[
      { 'MainMenu--scrolled': documentIsScrolled },
      { 'MainMenu--en': $i18n.locale === 'en' }
      ]" :key="$i18n.locale">
    <div class="Wrapper MainMenu-Box">
      <BaseLogo/>
      <MainNav v-show="$screen.width > 959"/>
      <LocaleSwitcher/>
      <BaseButtonBurger
          v-show="$screen.width < 960"
          class="MainMenu-Burger"
          @click.native="show = !show"
      >
        <img loading="lazy" src="@/assets/images/burger.svg"
             alt="" class="Burger-Img">
      </BaseButtonBurger>
      <transition v-show="$screen.width < 959" name="fade">
        <div v-show="show" class="MainMenu-Aside" >
          <div class="Wrapper MainMenu-AsideHeader">
            <BaseLogo/>
            <BaseButtonBurger
                class="MainMenu-Burger"
                @click.native="show = !show"
            >
              <img src="@/assets/images/close.svg"
                   alt="" class="Burger-Img">
            </BaseButtonBurger>
          </div>
          <MainNav style="margin: 112px 0 135px;" @click.native="show = !show"/>
          <div class="Wrapper">
            <a href
               @click.prevent="scrollTo('form'), show = !show"
               class="Button MainMenu-AsideButton">
            <span class="Button-Text">
              {{ $t('mainNav.button') }}
            </span>
              <div class="Button-Shadow"></div>
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import MainNav from "./MainNav";
import LocaleSwitcher from "./LocaleSwitcher";
import {scrollTo} from "../assets/utils/utils";

export default {
  name: "MainMenu",
  components: {LocaleSwitcher, MainNav},
  data() {
    return {
      show: false,
      documentIsScrolled: false,
      headerTop: 68
    }
  },
  methods: {
    scrollTo,
    onScroll() {
      this.documentIsScrolled = window.scrollY > this.headerTop
    }
  },
  mounted() {
    this.headerTop = this.$screen.width < 959 ? 68 : 92
    this.documentIsScrolled = window.scrollY > this.headerTop
    window.addEventListener('scroll', this.onScroll, { passive: true })
  }
}
</script>

<style lang="scss">
.MainMenu {
  position: absolute;
  top: 68px;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 20px 0;
  transition: .3s;
  transition-property: padding, box-shadow, background-color;

  @media (min-width: $screen-l) {
    top: 92px;
    padding: 54px 15px;
  }

  &--en {
    top: 0;
  }

  &--scrolled {
    position: fixed;
    top: 0;
    background-color: var(--color-bg-menu);
    box-shadow: 0 5px 5px var(--color-text-main2);

    @media (min-width: $screen-l) {
      padding: 24px 15px;
    }
  }

  &-Box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-body);
    scrollbar-width: thin;
  }

  &-AsideHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
  }

  &-AsideButton {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: 220px
  }

  &-Burger {
    border: 2px solid var(--color-text-main2);
  }

  .Button {
    width: 100%;
  }
}

.Logo {
  width: 132px;

  @media (min-width: $screen-l) {
    width: 234px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
