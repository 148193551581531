<template>
  <div class="BaseCard">
    <div class="BaseCard-Header">
      <div class="BaseCard-IconWrap">
        <BaseIcon :name="cardInfo.name" :width="cardInfo.width" :height="cardInfo.height" class="BaseCard-Icon"/>
      </div>
      <h4 class="Title Title--h4 BaseCard-Title" v-html="cardInfo.title"></h4>
    </div>
    <p class="Text BaseCard-Text" v-html="cardInfo.text"></p>
    <img loading="lazy" src="@/assets/images/bottom-border.svg" alt="bottom-border" class="BaseCard-Bottom">
  </div>
</template>

<script>
//import BaseIcon from "./BaseIcon";
export default {
  name: "BaseCard",
  // components: {BaseIcon},
  props: {
    cardInfo: {
      type: Object,
      required: false,
    }
  }
}
</script>

<style lang="scss">
.BaseCard {
  padding: 48px 24px 38px;
  position: relative;
  text-align: center;

  @media (min-width: $screen-l) {
    padding: 72px 31px 60px 28px;
    text-align: left;
  }

  &:before {
    content: "";
    position: absolute;
    top: 82.5%;
    left: -18px;
    z-index: 0;
    box-shadow: 10px 10px 5px var(--color-body);
    filter: blur(7px);
    width: 50px;
    height: 53px;
    background: var(--color-body);

    @media (min-width: $screen-l) {
      width: 31px;
      top: 84.5%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 83%;
    right: -11px;
    z-index: 0;
    box-shadow: 10px 10px 5px var(--color-body);
    filter: blur(7px);
    width: 30px;
    height: 53px;
    background: var(--color-body);

    @media (min-width: $screen-l) {
      top: 84%;
      width: 50px;
      right: -18px;
    }
  }

  &-Header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: $screen-l) {
      align-items: flex-start;
      height: 237px;
      margin-bottom: 35px;
    }
  }

  &-Icon {
    //stroke: #2BF12E;
    //fill: #2BF12E;
    width: 122px;
    margin-bottom: 38px;
    @media (min-width: $screen-l) {
      width: 150px;
      margin-bottom: 38px;
    }
  }

  &-Bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: -1;
  }

}

</style>