<template>
  <section class="Resources">
    <div class="Wrapper">
      <h2 id="resources-title" class="Title Title--h2 Resources-Title"></h2>
    </div>

    <swiper
      class="Resources-Slider swiper"
      :options="sliderLogosOptions"
      ref="slider"
    >
      <swiper-slide
        class="Resources-SliderSlide"
        v-for="(logo, i) in resources"
        :key="i"
      >
        <img :src="require(`@/assets/images/${logo}`)" alt="photo">
      </swiper-slide>    
    </swiper>
  </section>
</template>

<script>
import {textAnimation, isInViewport} from "../assets/utils/utils";
import { Autoplay } from 'swiper'

export default {
  name: "Resources",
  data() {
    return {
      resources: [
          "resources/google.svg",
          "resources/snapchat.svg",
          "resources/twitter.svg",
          "resources/facebook.svg",
          "resources/tiktok.svg",
          "resources/apple.svg",
      ],
      isTextTyped: false,
      sliderLogosOptions: {
        modules: [Autoplay],
        slidesPerView: 'auto',
        spaceBetween: 12,
        loop: true,
        loopAdditionalSlides: 10,
        speed: 5000,
        autoplay: {
          delay: 0
        },
        breakpoints: {
          960: {
            spaceBetween: 12
          },
          1248: {
            spaceBetween: 24
          }
        }
      }
    }
  },
  methods: {
    scrollHandler() {
      if (!this.isTextTyped && isInViewport('.Resources')) {
        this.isTextTyped = true

        const texts = [
          {
            selector: '.Resources-Title',
            text: this.$t('resources.title')
          }
        ]

        textAnimation(texts)        
      }        
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style lang="scss">
.Resources {
  padding: 30px 0 40px;

  @media (min-width: $screen-l) {
    padding: 0 0 100px;
  }

  h2 {
    margin-bottom: 20px;

    @media (min-width: $screen-l) {
      margin-bottom: 30px;
    }
  }

  &-Slider {
    .swiper-wrapper {
      transition-timing-function: linear;
    }    
  }

  &-SliderSlide {
    width: 160px !important;
    
    @media (min-width: $screen-l) {
      width: 327px !important;
    }
  }
}
</style>
