<template>
  <div class="Modal">
    <ModalFormSent v-if="content === 'formsent'" @click.native="hideModal" />
    <ModalPromo v-if="content === 'promo'" @hide-modal-promo="hideModal" />
    <ModalArticle v-if="content === 'article'" @hide-modal-article="hideModal" />
    <ModalSocials v-if="content === 'socials'" @click.native="hideModal" />
    <ModalVideo
      v-if="content === 'video'"
      :videoUrl="videoUrl"
      @click.native="hideModal"
    />
    <img src="@/assets/images/modal-border-bottom.svg" alt="" />
  </div>
</template>

<script>
import ModalFormSent from "./ModalFormSent";
import ModalSocials from "./ModalSocials";
import ModalVideo from "./ModalVideo";
import ModalPromo from "./ModalPromo";
import ModalArticle from "./ModalArticle";
export default {
  name: "BaseModal",
  components: {
    ModalSocials,
    ModalFormSent,
    ModalVideo,
    ModalPromo,
    ModalArticle 
  },
  props: {
    content: {
      type: String,
      default: () => {}
    },
    videoUrl: {
      type: [String],
      default: null
    }
  },
  methods: {
    hideModal() {
      this.$modal.hide("Modal");
    },
  }
};
</script>

<style lang="scss">
.Modal {
  position: relative;
  background: var(--color-body);

  > img {
    position: absolute;
    width: 100%;
    bottom: 0;
    pointer-events: none;
  }
}
</style>
