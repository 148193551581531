import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../plugins/i18n'

Vue.use(VueRouter)

const Article = () => import('@/views/article/_article');
const Career = () => import('@/views/Career');
const Vacancy = () => import('@/views/Vacancy');
const Contacts = () => import('@/views/Contacts');

const routes = [
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supported_locales = (process.env.VUE_APP_I18N_SUPPORTED_LOCALE || '').split(',')

      if (!supported_locales.includes(locale)) {
        return next('ru')
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'career',
        name: 'Career',
        component: Career,
        beforeEnter(to, from, next) {
          if (i18n.locale !== 'ru') {
            next({ name: 'Home' })
          }
          next()
        }
      },
      {
        path: 'vacancy/:id',
        name: 'Vacancy',
        props: true,
        component: Vacancy,
        beforeEnter(to, from, next) {
          if (i18n.locale !== 'ru') {
            next({ name: 'Home' })
          }
          next()
        }
      },
      {
        path: 'article/:id',
        name: 'article',
        component: Article,
        beforeEnter(to, from, next) {
          if (i18n.locale !== 'ru') {
            next('')
          }
          next()
        }
      }
    ]
  },
  {
    path: '*',
    redirect() {
      return i18n.locale
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        inline: 'start',
        behavior: 'smooth'
      }
    }

    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  },
  routes
})

export default router
