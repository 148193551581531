import '@/assets/styles/main.scss'
import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import router from './router'
import VueScreen from 'vue-screen';
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import VModal from 'vue-js-modal'
import LottieAnimation from 'lottie-web-vue'
import 'animate.css';
import store from './store'
import VueMeta from 'vue-meta'

Vue.use(LottieAnimation)
Vue.use(VueAwesomeSwiper)
Vue.use(VueScreen)
Vue.use(VModal)
Vue.use(VueMeta)

Vue.config.productionTip = false

const requireComponent = require.context(
    './components',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
      camelCase(
          fileName
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')
      )
  )

  Vue.component(
      componentName,
      componentConfig.default || componentConfig
  )
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
