<template>
  <div class="">
    <BaseButtonBurger class="ModalSocials-Close" @click="$emit('hideModal')">
      <img loading="lazy" src="@/assets/images/close.svg"
           alt="close">
    </BaseButtonBurger>
    <div class="Video Video--16by9">
      <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<style lang="scss">
.Video {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
      display: block;
      content: ""
  }

  &--21by9::before {
    padding-top: 42.8571428571%
  }

  &--16by9::before {
    padding-top: 56.25%
  }

  &--4by3::before {
    padding-top: 75%
  }

  &--1by1::before {
    padding-top: 100%
  }

  embed,
  iframe,
  object,
  video{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0
  }
}
</style>

<script>
export default {
  props: {
    videoUrl: String
  },
  computed: {
    videoId() {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = this.videoUrl.match(regExp)

      return (match && match[2].length === 11) ? match[2] : null      
    }
  }
}
</script>
