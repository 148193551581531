<template>
  <div class="Home">
    <TheHeader/>
    <AboutUs />
    <Resources v-if="$i18n.locale === 'ru'" />
    <Advantages />
    <Clients />
    <Reviews />
    <Interviews v-if="$i18n.locale === 'ru'" />
    <FormBox />
  </div>
</template>

<script>
import Interviews from "../components/Interviews";
import Resources from "../components/Resources";
const TheHeader = () => import(/* webpackPrefetch: true */ '@/components/TheHeader.vue');
const AboutUs = () => import(/* webpackPrefetch: true */ '@/components/AboutUs.vue');
const Advantages = () => import(/* webpackPrefetch: true */ '@/components/Advantages.vue');
const Clients = () => import(/* webpackPrefetch: true */ '@/components/Clients.vue');
const Reviews = () => import(/* webpackPrefetch: true */ '@/components/Reviews.vue');
const FormBox = () => import(/* webpackPrefetch: true */ '@/components/FormBox.vue');


export default {
  name: 'Home',
  components: {
    Resources,
    Interviews,
    Reviews,
    Clients,
    Advantages,
    AboutUs,
    TheHeader,
    FormBox
  }
}
</script>
