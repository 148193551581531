<template>
  <swiper :options="generalOptions" class="SwiperSlider swiper">
    <template v-if="sliderInfo">
      <swiper-slide
        v-for="(slide, i) in sliderInfo"
        :key="i"
        class="SwiperSlide"
      >
        <div class="SwiperSlide-Content">
          <img
            :src="require(`@/assets/images/${slide.icon}`)"
            alt="icon"
            class="swiper-lazy SwiperSlide-Icon"
            :style="{ minHeight: slide.minHeight }"
          />
          <h5 class="Title Title--h5 SwiperSlide-Title">{{ slide.title }}</h5>
          <p class="Text SwiperSlide-Text" v-html="slide.text"></p>
          <div class="SwiperSlide-Footer">
            <img
              :src="
                slide.stars ? require(`@/assets/images/${slide.stars}`) : ''
              "
              alt=""
              class="SwiperSlide-Rate"
            />
            <div class="Text SwiperSlide-Date" v-html="slide.date"></div>
          </div>
        </div>
      </swiper-slide>
    </template>
    <template v-if="interviews">
      <swiper-slide
        v-for="(interview, i) in interviews"
        :key="i"
        class="SwiperSlide InterviewSlide"
      >
        <div class="InterviewSlide-Head">
          <img
            :src="require(`@/assets/images/${interview.logo}`)"
            alt="logo"
            class="InterviewSlide-Logo"
          />
          <div
            class="Text SwiperSlide-Date InterviewSlide-Date"
            v-html="interview.date"
          ></div>
        </div>
        <h5
          class="Title Title--h5 SwiperSlide-Title InterviewSlide-Title"
          v-html="interview.title"
        ></h5>
        <p
          class="Text SwiperSlide-Text InterviewSlide-Text"
          v-html="interview.text"
        ></p>
        <a
          :href="interview.link"
          target="_blank"
          class="Text InterviewSlide-Link"
        >
          <span>
            Продолжить чтение
          </span>
          <img
            src="@/assets/images/green-arrow.svg"
            alt="arrow"
            class="InterviewSlide-LinkImg"
          />
        </a>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script>
import { Autoplay } from "swiper";
export default {
  name: "Slider",
  props: {
    sliderInfo: {
      type: Array,
      required: false
    },
    interviews: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      options: {
        slidesPerView: "auto",
        centeredSlides: false,
        cssMode: false,
        spaceBetween: 29,
        preloadImages: false,
        lazy: true,
        breakpoints: {
          960: {
            spaceBetween: 60,
            slidesPerView: 3,
          }
        }
      },
      optionsLooped: {
        modules: [Autoplay],
        loop: true,
        loopAdditionalSlides: 3,
        stopOnLastSlide: false,
        speed: 10000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }
      }
    };
  },
  computed: {
    generalOptions() {
      return this.sliderInfo
        ? { ...this.options, ...this.optionsLooped }
        : this.options;
    }
  }
};
</script>

<style lang="scss">
.SwiperSlider {
  height: 100%;
  cursor: ew-resize;

  .SwiperSlide {
    width: 270px;
    min-height: 100%;
    height: auto;
    padding: 36px 13px 26px;
    border: 2px solid var(--color-text-main2);
    position: relative;
    text-align: center;

    @media (min-width: $screen-l) {
      width: 777px !important;
      padding: 64px 21px 45px 58px;
      text-align: left;
    }

    &:before {
      content: "";
      position: absolute;
      top: -30px;
      left: 49%;
      transform: translate(-50%, 0);
      z-index: 0;
      box-shadow: 10px 10px 5px var(--color-body);
      filter: blur(7px);
      width: 94%;
      height: 53px;
      background: var(--color-body);

      @media (min-width: $screen-l) {
        width: 96%;
        left: 49.5%;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 49%;
      transform: translate(-50%, 0);
      z-index: 0;
      box-shadow: 10px 10px 5px var(--color-body);
      filter: blur(7px);
      width: 94%;
      height: 53px;
      background: var(--color-body);

      @media (min-width: $screen-l) {
        width: 96%;
        left: 49.5%;
      }
    }

    &-Content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      @media (min-width: $screen-l) {
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 37px;
      }
    }

    &-Icon {
      margin-bottom: 22px;
      max-width: 250px;
      min-height: 110px;
      @media (min-width: $screen-l) {
        margin-bottom: 29px;
      }
    }

    &-Title {
      margin-bottom: 16px;
    }

    &-Text {
      margin-bottom: 20px;
    }

    &-Footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-Rate {
      width: 104px;

      @media (min-width: $screen-l) {
        width: auto;
      }
    }

    &-Date {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 16px;
      }
    }
  }

  .InterviewSlide {
    width: 270px;
    min-height: 100%;
    height: auto;
    padding: 36px 13px 26px;
    border: 4px solid var(--color-border);
    position: relative;
    text-align: center;

    @media (min-width: $screen-l) {
      width: 332px !important;
      padding: 42px;
      text-align: left;
    }

    &:before,
    &:after {
      content: none;
    }

    &-Head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;

      @media (min-width: $screen-l) {
        margin-bottom: 42px;
      }
    }

    &-Logo {
      width: 103px;
      @media (min-width: $screen-l) {
        width: 138px;
      }
    }

    &-Title {
      margin: 0 25px 16px;

      @media (min-width: $screen-l) {
        margin: 0 0 16px;
      }
    }

    &-Date {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 16px;
      }
    }

    &-Text {
      margin-bottom: 41px;

      @media (min-width: $screen-l) {
        margin-bottom: 51px;
      }
    }

    &-Link {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.2;

      @media (min-width: $screen-l) {
        justify-content: left;
        font-size: 16px;
      }
    }

    &-LinkImg {
      width: 14px;
      margin-left: 10px;
      margin-top: -2px;

      @media (min-width: $screen-l) {
        width: auto;
      }
    }
  }
}

.swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  overflow-y: hidden !important;
}
</style>
