<template>
  <div class="LocaleSwitcher">
    <div v-for="locale in locales" :key="locale" @click="switchLocale(locale)" class="LocaleSwitcher-Btn"
         :class="{'LocaleSwitcher-Btn--active': $i18n.locale === locale}">
      {{ locale }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: (process.env.VUE_APP_I18N_SUPPORTED_LOCALE || '').split(',')
    }
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        const to = this.$router.resolve({params: {locale}})
        this.$router.push(to.location)
      }
    }
  }
}
</script>

<style lang="scss">
.LocaleSwitcher {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &-Btn {
    cursor: pointer;
    margin-right: 20px;
    opacity: 0.6;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      opacity: 1;
    }
  }

}
</style>
