<template>
  <button class="Burger" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButtonBurger",
}
</script>

<style lang="scss">
.Burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &:active {
    img {
      width: 16px;
    }
  }

  @media (min-width: 1024px) {
    &:hover {
      img {
        width: 16px;
      }
    }
  }

  img {
    width: 10px;
    transition: .3s;
  }
}
</style>