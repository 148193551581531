<template>
  <svg :width="width" :height="height"
       xmlns="http://www.w3.org/2000/svg"
       preserveAspectRatio="xMaxYMax meet">
    <use :xlink:href="iconPath"/>
  </svg>
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    name: {
      String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
  computed: {
    iconPath() {
      return `${require('@/assets/images/icons.svg')}#${this.name}`;
    },
  }
}
</script>

<style scoped>

</style>