import BaseModal from "@/components/BaseModal";
import Typed from 'typed.js';

export function scrollTo(nav) {
    const el = document.getElementById(`${nav}`)
    if (!nav) {
        return
    }

    el.scrollIntoView({
        block: "start",
        behavior: "smooth",
    });

}


export function showModal(content) {
    this.$modal.show(
        BaseModal,
        {content},
        {
            name: 'Modal',
            width: 488,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            clickToClose: false,
        },
        {}
    );
}


export function isSafari() {
    const uA = navigator.userAgent;
    const vendor = navigator.vendor;
    return /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA) 
}


export function textAnimation(texts) {
    let index = 0

    function typed(text) {
        const options = {
            strings: [text.text],
            typeSpeed: 30,
            showCursor: false,
            onComplete: () => {
                if (++index < texts.length) {
                    typed(texts[index])
                }
            }
        }

        new Typed(text.selector, options)
    }

    if (isSafari()) {
        texts.forEach(el => {
            document.querySelector(el.selector).innerHTML = el.text
        })
    } else {
        typed(texts[index])
    }
}


export function isInViewport(selector) {
    const box = document.querySelector(selector)
    const rect = box.getBoundingClientRect()
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
}
