<template>
  <div id="app">
    <!--    <ThePreloader/>-->
    <Banner v-if="$i18n.locale === 'ru'" />
    <MainMenu />
    <main class="Main">
      <router-view :key="$i18n.locale" />
    </main>
    <TheFooter />
  </div>
</template>
<script>
import MainMenu from "./components/MainMenu";
// import ThePreloader from "./components/ThePreloader";
import Banner from "./components/Banner";
// import {showModal} from "./assets/utils/utils";
import BaseModal from "./components/BaseModal";
const TheFooter = () =>
  import(/* webpackPrefetch: true */ "@/components/TheFooter.vue");

export default {
  name: "App",
  components: {
    Banner,
    // ThePreloader,
    MainMenu,
    TheFooter
  },
  metaInfo() {
    return {
      title: this.$t("title"),
      meta: [
        {
          name: "description",
          content: this.$t("description")
        }
      ]
    };
  },
  methods: {
    opened() {
      clearTimeout(this.timer);
    },
    openModal(content) {
      this.$modal.show(
        BaseModal,
        { content },
        {
          name: "Modal",
          width: 488,
          height: "auto",
          adaptive: true,
          scrollable: true,
          clickToClose: false
        },
        {
          opened: this.opened
        }
      );
    },
  },
  mounted() {
    if (this.$i18n.locale === "ru") {
      this.timer = setTimeout(() => {
        if (this.$i18n.locale !== "ru") return;
        this.openModal("article");
      }, 1000);
    }
  },
  beforeDestroy() {
    this.opened();
  }
};
</script>

<style lang="scss">
.Main {
  padding-top: 110px;

  @media (min-width: $screen-l) {
    padding-top: 160px;
  }
}
</style>
