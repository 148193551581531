<template>
  <div>
    <template v-if="$route.name === 'Home'">
      <a href @click.prevent="scrollToTop">
        <img loading="lazy" src="@/assets/images/logo.svg" alt="" class="Logo MainMenu-Logo">
      </a>
    </template>
    <template v-else>
      <router-link
        :to="{ name: 'Home', params: { lang: $i18n.locale }}"
      >
        <img loading="lazy" src="@/assets/images/logo.svg" alt="" class="Logo MainMenu-Logo">
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseLogo",
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>

</style>