import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=356d5bd0&"
import script from "./Banner.vue?vue&type=script&lang=js&"
export * from "./Banner.vue?vue&type=script&lang=js&"
import style0 from "./Banner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports