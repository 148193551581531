import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: true,
        socials: [
            {
                href: "https://t.me/syndicate_gg",
                icon: "telegram.svg",
                coloredIcon: "telegram-colored.svg",
                alt: "telegram"
            },
            {
                href: "https://www.facebook.com/SyndicateTop/",
                icon: "facebook.svg",
                alt: "facebook"
            },
            {
                href: "https://www.instagram.com/syndicate__g/",
                icon: "insta.svg",
                coloredIcon: "inst-colored.svg",
                alt: "instagram"
            },
        ],
        articles: [
            {
                id: "traffic-dictionary",
                title: "Словарь арбитражника трафика",
                disclaimer: [
                    "Новичку в арбитраже легко запутаться в тематическом сленге. Поэтому мы подготовили полный словарь по арбитражу трафика, где вы найдете всевозможные термины, понятия, сленг и аббревиатуры. Для удобства все разделено по категориям.",
                ],
                img: "traffic-img.png",
                articles: {
                    title1: "1.Основные термины:",
                    text1: [
                        "<span>А/Б тест (Сплит-тест)</span> — метод маркетингового исследования, при котором выясняется, при каких условиях конверсия лендинга/объявления/прокладки будет больше. Суть теста заключается в том, что одной и той же аудитории показываются различные рекламные материалы, а затем по статистике выясняется, какие из них оказались наиболее эффективными. Самым простым способом протестировать лендинг является использование CPAtracker. Качественно протестировать объявления можно, если пролить на каждое из них сумму, не меньше стоимости 1 лида.",
                        "<span>Адверт (Advert, Advertiser)</span> — заказчик или рекламодатель, который предлагает сам оффер и платит партнерке за определенные действия по офферу (подтвержденный заказ, регистрация и т.п). Также адвертами называют вебмастеров, которые льют трафик на себя (на собственные офферы).",
                        "<span>Адалт (Adult)</span> — общее название ниши товаров, а также веб-сайтов со взрослой тематикой",
                        "<span>Аппрув (Approve)</span> — % подтвержденных целевых действий (чаще всего подтвержденные заказы на покупку товаров) на одностраничнике, за которое начисляется денежное вознаграждение.",
                        "<span>Апсейл (upsale)</span> — допродажа товаров специалистами колл-центра рекламодателя/CPA-сети. На апсейлах чаще всего строится прибыль рекламодателей.",
                        "<span>Арбитраж трафика</span> — это покупка и последующая перепродажа ранее купленного интернет-трафика на более выгодных условиях. Другими словами — это когда вы покупаете трафик в одном месте и монетизируете его в другом, зарабатывая на разнице цен. Пример: пополняем рекламный кабинет в ВК, настраиваем таргетинг рекламных объявлений на целевую аудиторию (группа ВК по какой-то тематике). Дальше по Вашим рекламных объявлениям люди переходят на сайт, совершают целевое действие (делают заказ, регистрируются), которое оплачивается CPA-сетью/рекламодателем. Отсюда CPA - cost per action - оплата за целевые действия.",
                        "<span>Баннер (Banner)</span>, в общепринятом понимании — единица рекламного креатива, представляющая собой большой блок с картинкой (статичной или анимированной). Распространен в основном на новостных или тематических сайтах и прочит интернет-площадках (форумы, блоги, интернет-магазины).",
                        "<span>Бид (Bid)</span> — максимальная ставка за клик по рекламному объявлению, используется на рекламных площадках с аукционной системой оплаты, например Google Adwords, Yandex Direct, тизерные сети.",
                        "<span>Блек лист (Blacklist)</span> — список источников, рекламных площадок (сайтов/сетей сайтов), на которых не будет показываться ваша реклама. Используется чаще всего в тизерных и контекстно-медийных сетях.",
                        "<span>Вайт лист (White list)</span> — список источников, на которых будет показываться ваша реклама. Стоит отметить, что вайт лист подразумевает показ рекламы ТОЛЬКО на указанных в списке ресурсах. Используется чаще всего в тизерных и контекстно-медийных сетях.",
                        "<span>Вайт пейдж (White page)</span>  — \"белая\" страница, которую мы показываем модератору, затем после прохождение модерации - мы подменяем страницу на оффер-пейдж.",
                        "<span>Вебмастер (Webmaster)</span> — арбитражник. Частное лицо или штатный сотрудник, который занимается настройкой рекламы товаров/услуг рекламодателя в различных источниках трафика.",
                        "<span>Вертикаль</span> — тип оффера, его направленность. Например: товарка, дейтинг, игры и т.д. По вертикали часто бывает понятно, какое действие по офферу необходимо — регистрация, подтвержденная заявка, подписка и т.д.",
                        "<span>Витрина</span> — тип оффера, представляющий из себя витрину с несколькими товарами. В отличие от обычных офферов, витрины предоставляют более широкий выбор продуктов, скомпонованных по определенной тематике.",
                        "<span>Дейтинг (Dating)</span> — тип офферов, связанный с сайтами знакомств. Как правило, действием здесь является регистрация, либо регистрация и выполнение определенных действий после нее.",
                        "<span>Диплинк (Deeplink)</span> — инструмент партнерской программы, который позволяет направлять трафик на любую страницу на сайте рекламодателя.<br> Например, если ваше рекламное объявление посвящено какому-то определенному продукту на сайте интернет-магазина, то с помощью диплинка вы сможете направить трафик не на главную, а на страницу этого продукта.",
                        "<span>Инсталл (Install, App Install)</span> — установка приложения. Чаще используется в контексте типа оффера, по которому выполненным действием считается установка приложения из магазина (App Store, Google Play)",
                        "<span>Кейс (Case) в контексте CPA</span>  — описание последовательности определённых действий, при выполнении которых, автор кейса получил профит. Часто кейсом является связка источник-креатив-ПП-оффер.",
                        "<span>Конверт</span> (от слова Конверсия, CR, Conversion Rate).  Конвертом в CPA-тематике называют коэффициент конверсии — отношение посетителей, совершивших целевое действие на странице, к общему количеству посетителей. Выражается в процентах. Например, 10% — очень хороший конверт. Показывает, насколько хорошо пользователи реагируют на ваш рекламный материал.",
                        "<span>Конверсия</span> — смотри \"Лид\". Говорят, что если пользователь, кликнувший по рекламному объявлению, осуществил целевое действие на сайте, то произошла конверсия (мы получили лид).",
                        "<span>Креатив (Creative)</span> — рекламная единица, например тизер/баннер/объявление/видео.",
                        "<span>Лендинг (Лендос, Landing Page)</span> — в переводе «посадочная страница». Страница, куда «приземляется» пользователь с рекламного объявления. Как правило, такой сайт представляет из себя одну страницу с длинной прокруткой, где расписаны все преимущества представляемого продукта. Смысл продажи товаров/услуг с одностраничников - \"заставить\" посетителя сделать целевое действие здесь и сейчас. Для этого на лендингах используются специальные \"триггеры\" - маркетинговые приемы в дизайне и копирайтинге. Например, ограничение по количеству акционных предложений, ограниченное время акции, социальные подтверждения других пользователей продукта, мнения экспертов, стрелки переходов от одного блока лендинга к другому.",
                        "<span>Лид (Lead)</span> — потенциальный покупатель, оставивший контактные данные для дальнейшей с ним коммуникации. В случае с нишей товаров с доставкой (реже - регистраций с подтверждением по телефону) задача колл-центра — превратить лида в конверсию, т.е. продать ему товар/подтвердить регистрацию.",
                        "<span>Локер (Locker)</span> — инструмент, блокирующий действия пользователя на сайте до тех пор, пока он не осуществит определенное действие, например отправка СМС, регистрация, скачка файла и т.д.",
                        "<span>Мидлет (Midlet)</span> — это приложение на платформе Java. Обычно такие приложения включают в себя игры для мобильных устройств и сотовых телефонов и будут работать даже на самых простых мобильных устройствах. Мидлет может отправлять оплату с телефона клиента при помощи SMS, для чего он должен согласиться с предлагаемыми условиями приложения.",
                        "<span>Микрозаймы (Payday loans)</span> — тип оффера, оплаченным действием по которому является выдача краткосрочного кредита.",
                        "<span>Мобайл (Mobile)</span> — широкое определение для всего мобильного трафика.",
                        "<span>Мотив, Мотивированный трафик</span> — как правило, это люди, которым платят за определенное действие, например регистрацию или заказ товара. Рекламодатели очень редко принимают такой вид трафика, чаще он считается фродом, или как минимум трафиком низкого качества. Исключение составляют, пожалуй, лишь мобильные инсталлы.",
                        "<span>Мусорность</span> - показатель в контекстной рекламе и SEO, показывающий отношение нецелевых обращений к общему количеству поисковых запросов.",
                        "<span>Оффер-пейдж (Offer page)</span> — страница с оффером. Целевая страница, на которую мы будем вести трафик после прохождения модерации. Встречается при использовании \"подмены\" для модерации в источнике трафика, если реклама оффера в источнике трафика запрещена, но нам очень хочется его \"пропихнуть\"!",
                        "<span>Охват</span> — число представителей целевой аудитории, вступивших в контакт с конкретным средством рекламы или сочетанием коммуникационных средств в течение определенного отрезка времени.",
                        "<span>Парковка домена</span> — регистрация доменного имени на DNS-серверах партнерки для дальнейшего размещения на нем лендингов и прокладок офферов. Этот инструмент бывает полезен в случаях, когда соцсети блокируют ваши прямые партнерские ссылки.",
                        "<span>Переходы</span> — общее количество не уникальных заходов по вашей ссылке, например, если один и тот же человек несколько раз заходит на вашу целевую страницу.",
                        "<span>Показ</span> – единичный факт предъявления пользователю какого-либо рекламного материала. Этот термин традиционно используется в том случае, когда речь идет о модели оплаты CPV.",
                        "<span>Попап (Popup)</span> — всплывающее окно с рекламным объявлением. Появляется на переднем плане. Чаще всего, открывается при совершении определенного действия на сайте.",
                        "<span>Попандер (PopUnder)</span> — тоже всплывающее окно с рекламой, однако открывается оно, как правило, на заднем плане в новом окне браузера.",
                        "<span>Постбэк (Postback)</span> – это запрос системы к серверу, если произошло определенное событие. В случае с CPA, это запрос, который отправляет сервис партнерки на сайт вебмастера, если посетитель совершил определенное действие. Таким образом в статистике личного кабинета вебмастера можно увидеть количество переходов/конверсий с определенного объявления.",
                        "<span>Реферал (Referral)</span> — человек, зарегистрировавшийся на сайте/сервисе по ссылке, либо реферальному коду другого человека. Тот, по чьей ссылке зарегистрировались, получает вознаграждение согласно условиям реферальной программы.",
                        "<span>Ротатор</span> — скрипт, позволяющий осуществлять поочередный показ баннеров в одном и том же рекламном блоке. Пример его работы: вы зашли на страницу и увидели в блоке один баннер. Обновили страницу — в том же рекламном блоке отображается уже другой баннер.",
                        "<span>Семантическое ядро</span> - полный список слов, словоформ и синонимов, входящих в поисковые запросы, релевантные нашему CPA-офферу.",
                        "<span>Субайди (SubID)</span> — инструмент, позволяющий детально отслеживать статистику и собрать всю информацию о действиях . При использовании данного инструмента, вы можете, анализируя трафик, составить список качественных источников и отключить источники трафика с низкой конверсией.",
                        "<span>Трафик (Traffic)</span> — по аналогии с дорожным движением, это поток посетителей на какой-либо ресурс, либо переходящих по вашей ссылке.",
                        "<span>Фулфилмент (Fulfillment)</span> — комплекс операций с момента оформления заказа покупателем и до момента получения им покупки.",
                        "<span>Холд (Hold)</span> — время, на протяжении которого партнерка удерживает запрошенную выплату вебмастера. Делается это для проверки качества трафика во избежание фрода со стороны вэба. Если с трафиком все ОК (например, товары выкупили с почты), то по окончании холда деньги переводятся на счет вебмастера.",
                        "<span>Шейв</span> — ситуация, когда вебмастеру идет отказ по лидам (и соответственно не выплачивается вознаграждение), которые на самом деле были успешно приняты и оплачены.",
                        "<span>Баннерная слепота (banner blindness)</span> — это когда пользователей так достало какое-то ваше объявление, что они перестают его замечать. А кроме вашего объявления есть и объявления конкурентов.",
                        "<span>Генерация лидов (lead generation)</span> — как только не используется, в узком и правильном смысле означает получение контактных данных заинтересованного клиента и не более того.",
                        "<span>Ревшафа (RevShare, Revenue Share)</span> — модель оплаты за лид, когда за трафик, привлеченный на сайт рекламодателя оплачивается только % от депозита. Например, лид, которого привлек вебмастер на сайт онлайн-казино сделал за 2 года пользования учетной записью 10 депозитов на общую сумму $2000. Вебмастер будет получать % от каждого депозита, пока он сотрудничает с CPA-сетью, через которую льет. Другой пример - интернет-магазины. Посетитель купил на общую сумму 12000 рублей за заказ (10 вещей), перейдя по рекламе вебмастера. В таком случае вебмастер получит % от всего чека, а не от 1 товара, на который льет трафик.",
                        "<span>Оффер (Offer)</span> — рекламное предложение, созданное рекламодателем. В буквальном смысле с английского переводится как \"выгодное предложение\". Являет собой уникальное торговое предложение, которое включает ряд качеств, характеристик, описанных на лендинге и в рекламных креативах. В CPA-маркетинге офферами называют товар, сервис, игру, подписку, заявку на получение услуги - все то, за что CPA-сеть/рекламодатель платит арбитражнику.",
                        "<span>Оффервол (Offer wall)</span> — дословно “стена предложений”. Это страница со множеством офферов, которые предлагается скачать, заказать, зарегистрироваться и т.д. Встречается чаще всего в мобильном трафике.",
                        "<span>Пинсабмит (Pin Submit)</span> — тип офферов, в которых оплата услуги идет подтверждением по СМС. Например, платное участие в розыгрыше iPhone, скачивание игры и т.п.",
                        "<span>Поток</span> — настроенный рекламный канал вебмастера в ПП по какому-либо офферу. Другими словами, поток — это партнерская ссылка, на которую вебмастер льет трафик.",
                        "<span>Пробники</span> — тип офферов, по которым высылаются бесплатные пробники для знакомства с продуктом. В дальнейшем монетизация происходит за счет повторных заказов этого продукта, уже за деньги.",
                        "<span>Свипстейки (Sweepstakes)</span> — тип оффера, часто встречаемый в зарубежных партнерках. Представляет из себя розыгрыш какого-либо продукта, где пользователь для участия должен оставить свои контакты.",
                        "<span>Бурж</span> – все, что связано с зарубежным трафиком, преимущественно из США и Западной Европы.",
                        "<span>CTR</span> – соотношение числа кликов по рекламе к общему количеству её показов. Формула расчета – число кликов/число показов * 100%.",
                        "<span>ROI</span> – показатель окупаемости вложений в рекламную кампанию. Рассчитывается по формуле ROI = (a-b)/b * 100%, где a – суммарный доход арбитражника, b – общие расходы на рекламу.",
                    ],
                    title2: "2.Словарь моделей оплаты:",
                    text2: [
                        "<span>CPA (cost per action)</span> - Оплата за определенное действие. Основная модель, которая делится на:",
                        "<span>◆</span> CPI (cost per install) — оплата за установку приложения или расширения.",
                        "<span>◆</span> CPL (cost per lead) — оплата за лид. Например, лидом является регистрация, звонок клиента или заявка на заказ товара в интернет-магазине.",
                        "<span>◆</span> CPS (cost per sell) — оплачиваются только пользователи, совершившие целевое действие на сайте рекламодателя.",
                        "<span>◆</span> RevShare (%) — вознаграждение в виде процента от потраченной или полученной пользователем суммы. Чаще всего используется в гемблинг- и финансовых офферах.",
                        "<span>Оплата в источниках трафика</span> - рекламные сети, в которых вы запускаете свою кампанию, могут списывать деньги с вашего баланса по трем основным моделям:",
                        "<span>◆</span> CPC (cost per click) — оплата за клик. Наиболее часто используется в контекстной, баннерной рекламе и в соцсетях.",
                        "<span>◆</span> CPM (cost per mille) — оплата за 1000 показов. Используется в любом источнике трафика.",
                        "<span>◆</span> CPA (cost per action) — оплата в источнике за выполненную конверсию. Самый непопулярный метод по некоторым причинам. Во-первых, у источника трафика есть возможность просматривать все ваши лендинги + оффер, на который вы направляете трафик. Во-вторых, она невыгодна, как бы не очевидно это не было.",
                        "<span>Вапклик (Wapclick)</span> — это технология оплаты контента на сайте без ввода номера и кода подтверждения. Один из способов монетизации мобильного трафика, при котором абонент соглашается со стоимость и условиями подписки нажимая кнопку “ОК” или “Загрузить”.",
                        "<span>Ребилл (Rebill)</span> — это автоматический платеж от пользователя для продления доступа к услуге. Например, у вас подключена услуга на 1 день за 100 рублей, по истечении 24 часов со счета автоматически будут списаны еще 100 рублей и услуга будет продлена еще на 1 день, и так далее, пока пользователь не отпишется от услуги.",
                    ],
                    title3: "3.Словарь основных вертикалей:",
                    text3: [
                        "<span>Товарка (e-commerce).</span> Одна из самых популярных вертикалей по всему миру. В неё входят средства для похудения, нутра, БАДы, дропшиппинг товары с китайских агрегаторов, партнёрские программы интернет-магазинов и т.д. Она привлекательна большим количеством офферов на любой вкус и относительной лояльностью источников трафика. Считается вертикалью для новичков, хотя для работы требуются знания и некоторый опыт.",
                        "<span>Вапклик (wapclick).</span> Вертикаль с подпиской пользователя на мобильный контент за определенную плату. Вебмастер получает комиссионные по revshare в виде процента от подписки пользователя или фиксированную выплату по CPA. Офферы делятся на 1click и 2click: в первом случае подписка подключается при нажатии на кнопку «подписаться», во втором - пользователю необходимо ввести номер телефона. Эта вертикаль популярна на развивающихся рынках, но не особо нравится рекламным площадкам, так как вебмастеры часто не указывают правила подписки, либо же об этом «забывает» рекламодатель.",
                        "<span>Гемблинг (gambling).</span> Вертикаль, посвящённая онлайн-казино. В ней вебмастер может работать по:",
                        "<span>◆</span> CPA (выплата за регистрацию или первый депозит).",
                        "<span>◆</span> RevShare (процент от проигранных в казино средств).",
                        "<span>◆</span> Hybrid (небольшая выплата за первый депозит и ревшара за последующие траты игрока).",
                        "Из-за законодательства в некоторых странах и правил рекламных сетей такие офферы иногда тяжело «пропихнуть» в рекламные площадки. Однако это не является невозможной задачей — достаточно набраться опыта и научиться грамотно клоачить.",
                        "<span>Утилиты (install)</span> — инсталл-офферы с выплатой за установку приложения или браузерного расширения. Несмотря на уходящую популярность, на инсталлы всё ещё активно льют многие вебмастера. Почему? Под них подходит любой источник и любое гео. Вопрос только в оптимизации и объемах. В инсталлах вполне возможно получать профит в $1000-2000 в сутки. А большой выбор офферов позволяет постоянно обновлять предложение и подбирать конвертящую связку «источник трафика — лендинг — оффер».",
                        "<span>Игры (Gaming)</span> — инсталл-офферы, но на игровые приложения для телефонов и планшетов (игры, тайм-киллеры, social-casino и т.д.) с внутренней монетизацией. Самые привлекательные офферы на данный момент, потому что их не нужно прятать от источника трафика и его модераторов. Здесь победит лучший креатив и высокий KPI, благодаря которому рекламодатель не поскупится на повышение выплат.",
                        "<span>Финансы (Finance)</span> — вертикаль финансовых бирж, опционов, банков, микрокредитных организаций и т.д. Здесь есть офферы с оплатой за депозит. Как правило, высокие требования к депозитам (мин. $200–$300) компенсируются высокой выплатой (до $500). Из-за популярности криптовалюты эти офферы оживились, и все большее количество вебов пробует себя в финансах.",
                        "<span>Свипстейки/Лидген (Sweepstake)</span> — вертикаль с розыгрышами рекламодателем какого-либо приза (топовые гаджеты, подарочные карты на внушительные суммы и т.д.) за ввод пользователем данных. В дальнейшем используется рекламодателем для отправки рекламных материалов. Делится на SOI (физический почтовый адрес, e-mail) и CC-submit (те же данные + кредитная карта с последующим биллингом). Распространен в Tier1 странах (США, Европа), реже — по другим гео.",
                        "<span>Дейтинг (Dating)</span> — знакомства, или одна из «вечных» тем. Вертикаль делится на мейнстрим (офферы типа Тиндера и Баду), вебкам и адалт (такие можно лить только с адалт-площадок). Здесь рекл очень внимательно следит за качеством трафика и часто отклоняет его, если юзеры плохо монетизируются.",
                        "<span>Образовательные, эссе, райтинг (Essay)</span> — написание дипломов, рефератов, курсовых. Вебмастер получает вознаграждение за каждый заказ. Источники не любят такие офферы, несмотря на их законность, так как написание чужих работ считается «неэтичным» на западе."
                    ],
                    title4: "4.Словарь видов рекламы:",
                    text4: [
                        "<span>Тизер (Teaser)</span> — рекламное сообщение, построенное как загадка, которое содержит часть информации о продукте, но при этом сам товар не демонстрируется. Представляет из себя, как правило, блок из небольшой картинки + текста. Также может косвенно быть релевантным офферу (по цвету и содержанию), но при это быть сильно привлекающим внимание.",
                        "<span>Баннерная реклама</span> - вид рекламы, размещаемой на различных сайтах в виде баннера: анимированной или статичной картинки, ведущей на продвигаемый ресурс.",
                        "<span>SEO трафик</span> - это привлечение пользователей на сайт благодаря продвижению в поисковых системах (Google, Yandex и т.д.).",
                        "<span>Нативная реклама</span> — альтернатива тизерным сетям. Эволюция тизерной рекламы, приобретающая вид и характеристику платформы, на которой она размещается. Она не похожа на обычные тизеры, поэтому пользователь может не догадаться о том, что этот материал размещен с коммерческой целью. Данный вид рекламы появился с целью избежания эффекта «баннерной слепоты».",
                        "<span>Кликандер, попандер</span> - всплывающие окна. Характеризуется дешевизной трафа и… ботами. После угроз Гугла избавить Интернет от “annoy or mislead” рекламы, многие вебы ушли из попандера, ввиду чего трафик в некоторых сетках достаточно сильно подешевел.",
                        "<span>Контекст (Я.Директ, AdWords, Bing Ads)</span> - второй по популярности источник. Тонны показов каждый день на любой вкус. Очень качественный трафик, который можно оптимизировать и анализировать по огромному количеству параметров.",
                        "<span>Таргетированная</span> – реклама, которая будет показываться только определенной целевой аудитории с общими признаками (гео, пол, возраст, интересы). Часто используется в соцсетях, так как именно там легче всего собрать информацию о пользователях и настроить показ. ",
                        "<span>Тизерная сеть</span> – рекламная сеть, где можно купить тизерную рекламу, или заработать на её показах. Используется оплата по CPC или CPM.",
                        "<span>Push-уведомления (пуши)</span> – всплывающие уведомления, которые показываются пользователю. Применяются как в мобильной, так и в десктопной рекламе. ",
                        "<span>SEO-продвижение</span> – продвижение оффера при помощи ключевых слов, часто использующихся в релевантных запросов. Используется на собственных вебсайтах, форумах, в блогах, иногда – в соцсетях."
                    ],
                    title5: "5.Работа с трафиком:",
                    text5: [
                        "<span>Дорвей (Doorway)</span> — инструмент для поискового спама. Это отдельные сайты, оптимизированные под один или множество поисковых запросов. Цель дорвея — попадание на высокие позиции в поисковой выдаче по этим запросом. При попадании на дорвей, пользователь перенаправляется на целевую страницу, например на лендинг или прокладку.",
                        "<span>Клоакинг</span> – способ маскировки редиректа, используется в основном на тех площадках, где та или иная вертикаль запрещена. Например, если арбитражник льет с Facebook на адалт-вертикаль. Иногда применяется при работе с запрещенным оффером видом трафика. Вкратце схема выглядит так: с разрешенной правилами площадки страницы через скрипт или с помощью разделения потоков в трекере идет редирект на лендинг.",
                        "<span>Преленд</span> – сайт-«прокладка», нужный в арбитраже для подогрева интереса клиента к офферу. В отличие от дорвеев, где содержимое не имеет значения, здесь упор делается на контент, с целью подогреть заинтересованность потенциального лида.",
                        "<span>Таргетинг</span> – сбор ЦА по определенным критериям: географическому положению (геотаргетинг), возраст, пол. В условиях офферов обычно определен таргетинг целевой аудитории, но иногда требуется экспериментировать в её поиске, самостоятельно задавать критерии показа в рекламных сетях, и т. д.",
                        "<span>Трафбэк (трафикбэк)</span> – перенаправление трафика. В арбитраже используется, если источник трафика не соответствует критериям оффера. Например, пользователь из нежелательного региона, перешедший по ссылке, автоматически перебрасывается на другую страницу.",
                        "<span>Трекер</span> – система отслеживания потоков трафика. В арбитраже применяется также для тестирования эффективности связок, перераспределения потоков трафика, анализа рекламной кампании. Популярные многофункциональные трекеры – Binom, Keitaro, Octotracker и др.",
                        "<span>Фрод</span> – от «fraud» (мошенничество). Означает некачественный трафик, в том числе – запрещенные его виды, и вообще любые способы обмануть оффера. Например, генерация фейковых лидов, или маскировка редиректа с запрещенных источников – фрод.",
                        "<span>Клоак (Cloak)</span> — программное обеспечение, позволяющее пользователю подменять страницы сайта не только во время модерации, но и во время открута (прятать реальную страницу от поисковых роботов), парсить (собирать) новые ip-адреса с подозрительной активностью. В отличии от подмены трекером, позволяет более долгое время \"прятать\" реальную страницу от модераторов, тем самым избегая бана аккаунта."
                    ],
                    title6: "6.Заключение:",
                    text6: [
                        "Таким образом, мы рассмотрели основные арбитражные термины. Это далеко не полный словарь арбитража и партнерского маркетинга, однако необходимые понятия и определения мы постарались перечислить. Надеемся, что наш словарь будет вам полезен."
                    ]
                },
            },
            {
                id: "how-to-start-earning-on-traffic-arbitrage",
                title: "Как начать зарабатывать на арбитраже трафика?",
                disclaimer: [
                    "Интерес к арбитражу трафика с каждым годом растет все больше и больше. Поэтому в нашей статье мы решили ответить на самые частые вопросы об арбитраже, а заодно поделились, сколько можно на нем заработать."
                ],
                img: "traffic-arbitrage.png",
                articles: {
                    title1: "1. Чем занимается арбитражник?",
                    text1: [
                        "<span>Трафик по своей сути</span> — это посетители сайта. На некоторые сайты приходят тысячи посетителей, а на некоторые — только единицы. Чем выше трафик — тем больше у владельца сайта возможностей заработать. Для того, чтобы повысить трафик владельцы сайтов обращаются к арбитражникам.",
                        "<span>Арбитражник</span> — это специалист, который закупает трафик в одном месте и перепродаёт его в другом, там где это выгоднее. Разница, полученная от перепродажи, будет являться заработком арбитражника.",
                        "Предположим вы купили 1000 посетителей для вашей арбитражной площадки за 10 долларов. Из этой 1000 человек 750 перешли на сайт заказчика, где 6 из них оформили заказ, из каждого из этих 6 посетителей вы получаете 10 долларов. Таким образом, ваш доход составит 50 долларов."
                    ],
                    title2: "2. А есть ли специальные программы, которые помогут облегчить работу?",
                    text2: [
                        "Конечно, как и в любой современной сфере, в арбитраже есть свои специальные инструменты, которые можно использовать для работы. Некоторые инструменты (конструкторы сайтов, SMM-планировщики) не являются узкоспециализированными, и используются и для многих других задач. Но есть и инструменты, которые “заточены” именно под людей, которые работают с трафиком — они замеряют собственный трафик, оценивают трафик конкурентов и т.д. Нет необходимости использовать все вспомогательные программы каждый раз, однако важно понимать их функционал и уметь оценивать пользу от их применения в работе.",
                        "Поскольку для создания площадки, куда потребуется привлекать трафик, может потребоваться создание отдельного сайта, для этих целей часто используют простые и понятные в использовании конструкторы сайтов, такие как Tilda. Чтобы планировать публикацию постов для арбитража в социальных сетях и собирать по ним статистику, могут пригодиться приложения типа Radaar или Smmplanner. AdsBridge и Anstrex помогут собирать информацию по трафику, переходам по ссылке, эффективности рекламы, информацию и статистику по рекламе конкурентов.",
                    ],
                    title3: "3. А что такое «белый» и «черный» арбитраж?",
                    text3: [
                        "Арбитраж делят на “белый” и “чёрный” в зависимости от того, над каким проектом вы работаете. Например, вы можете заниматься сбором трафика через контекстную и таргетированную рекламу для общедоступных  товаров и услуг. Такой арбитраж будет считаться “белым”: его правила понятны и рекламодателям и посетителям сайта, нет никаких подводных камней. Если же вы будете работать со специфическими проектами, которые доступны не каждому по причине официальных ограничений, типа возраста или недоступности в конкретной стране, использовать для этого кликбейтную рекламу или информацию, не соответствующую действительности, то такой арбитраж будет считаться “чёрным”. В работе с ним гораздо больше сложностей, так как многие площадки не разрешают использовать своё сетевое пространство для подобного рода рекламы.",
                    ],
                    title4: "4. А что с доходом? Сколько можно заработать на арбитраже трафика?",
                    text4: [
                        "Много. Автор книги «Трафик-бизнес» Дмитрий Вохонский утверждает, что арбитражник может зарабатывать от 5 до 50 тысяч долларов в месяц. Однако, важно понимать, что речь идёт про профессионалов своего дела, которые в искусстве владеют всеми доступными инструментами, знают все тонкости и имеют много опыта. Заработок среднего специалиста по арбитражу обычно колеблется от 700 до 3000 долларов.",
                        "Для начинающих арбитражников ситуация, как правило, выглядит иначе. В большинстве случаев главной целью начинающего арбитражника является выход в ноль. Это связано с тем, что по началу затраты на рекламу, как правило превышают доходы, получаемые от компаний.",
                        "Кроме того, арбитражник может работать и по другим специальностям. Многие из них устраиваются работать таргетологами, PPC-специалистами или affiliate-менеджерами. Знания, получаемые при работе арбитражником высоко ценятся в этих профессиях.",
                    ],
                    title5: "5. А где брать арбитражный трафик?",
                    text5: [
                        "Платный трафик можно получить с помощью размещения разного рода рекламы на площадках, где вы можете взаимодействовать со своей целевой аудиторией. Бесплатный трафик можно получить, если у вас уже есть раскрученная собственная площадка, или с помощью спам-рассылок.",
                    ],
                    title6: "6. А где продавать трафик?",
                    text6: [
                        "На сегодняшний существует большое количество различных партнёрских программ. Есть CPA-партнёрки, которые платят арбитражникам за выполнение определённых действий — переходы по ссылке, покупки товара и т.д. Некоторые арбитражники работают с SMS-партнёрками, однако они теряют актуальность в последнее время. Зато становятся всё более популярными партнёрские программы, по которым арбитражники получают доход от процента продаж. Но важным правилом арбитражников считается отказ от “слива” на одну партнёрку, поскольку, как правило, такие рекламные кампании не бывают успешными."
                    ],
                    title7: "7. Так стоит ли заниматься арбитражем?",
                    text7: [
                        "Каждый решает для себя сам, так как эта работа сопряжена с определёнными рисками. Если заниматься этим бездумно, не пытаясь разобраться во всех тонкостях, то есть риск слить все деньги впустую. Однако, если стать профессионалом, то можно научиться зарабатывать сотни тысяч рублей с одной кампании. По сути, доходы от арбитража безграничны, и если вы подойдёт к делу с умом, то сможете добиться больших успехов."
                    ]
                }
            },
            {
                id: "google-traffic-arbitrage",
                title: "Арбитраж трафика в Google",
                disclaimer: [
                    "Работать с Google Ads следует каждому, потому что это золотая жила ресурсов. Однако, в процессе возникает немало сложностей, но это того стоит. Среди них много нюансов, которые невозможно передать никакими мануалами и курсами. Лишь через личные тесты и полученный опыт можно добиться положительных результатов и выработать свой метод.",
                    "Стоит заметить, что тут так же имеется весьма жесткая конкуренция по высокочастотным ключевым запросам. Однако, существуют альтернативные стратегии предлагающие использование менее популярных ключей для удешевления трафика и снижения конкуренции, хотя такие методы предполагают большей вовлеченности и ответственного подхода в составлении списка ключевых запросов."
                ],
                img: "google-arbitrage.svg",
                articles: {
                    title1: "Как найти оффер для начала работы с GoogleAds?",
                    text1: [
                        "Одной из прелестей работы с GoogleAds является гибкость. Рекламу в Гугл возможно настраивать не лишь только когда юзер уже отыскивает что-нибудь определенное, но и когда он элементарно серфит онлайн в розысках чего-то свежего. Формат Discovery Ads специализирован как раз для того, чтобы предлагать юзерам что-нибудь свежее, к примеру, неповторимый оффер.",
                        "Например: вы запускаете объявления. Они станут видны сразу в 3-х пространствах:",
                        "<span>◆</span> на основной страничке видеохостинга YouTube, наряду с персонализированным контентом;",
                        "<span>◆</span> на вкладке соцсетей и акций почтового сервиса Gmail;",
                        "<span>◆</span> в персонализированной ленте новостей Discover Feed.",
                        "При данном подходе настраивать визуал объявлений под каждую площадку не надо. Хватит лишь единожды добавить креатив, порядка 5-ти описаний и заголовков и логотип фирмы. Реклама Google сама адаптирует формат под запросы и особенности каждой конкретной площадки.",
                        "В опциях можно адаптировать все аудиторные таргетинги и подавать рекламу не всем без разбору, а конкретной целевой аудитории. Очень важно чтобы ваш контент был колоритным, креативным, обладал интригующими заголовками, конкретными описаниями и прочими элементами, которые могли бы привлечь и удержать внимание пользователей.",
                        "В любом случае хорошей идеей будет так же обратиться за рекомендацией к менеджеру партнерской сети, если вы ведете сотрудничество с таковой.",
                    ],
                    title2: "Что можно и нельзя рекламировать в GoogleAds",
                    text2: [
                        "В первую очередь стоит помнить что реклама на детском контенте запрещена в принципе, что очень хорошо, т.к. это означает что весь наш контент имеет куда большую вероятность привлечь ЦА, и не надо переживать за то, что привлеченные дети будут сливать бюджет платформы",
                        "Однако, существуют и запрещенные тематики с полным списком которых можно ознакомиться на <a href='https://support.google.com/adspolicy/answer/6008942#con'>сайте Гугл</a>",
                        "Далее, остается выбор вертикали",
                        "<span>E-commerce</span> - максимально доступная и широкопрофильная вертикаль, в которой довольно легко выбрать свою нишу, при этом полностью соблюдая правила торговли на Гугл. Все что нужно для начала работы - это минимальная настройка рекламы и, желательно, наличие партнерки.",
                        "<span>Wapclick</span> - подписка на платный контент. Вебмастер получает плату после того как пользователь оформит покупку подписки. Существует в вариациях от одного до нескольких кликов. Тут следует по полной эксплуатировать наличие кнопок на объявлениях Гугл Адс и призывать потенциального пользователя к действию.",
                        "<span>Gambling</span> - запрещенная вертикаль во многих странах, поэтому прежде чем начать работу по данной сфере убедитесь, что у вас настроен прокси и клоака, дабы не схлопотать бан и не потерять выполненную работу. По этой же причине вертикаль довольно сложно продвигать, в т.ч. из-за высокой конкуренции, однако потенциальный доход весьма привлекателен.",
                    ],
                    title3: "Необходимые инструменты и расходники",
                    text3: [
                        "Самым важным инструментом при работе с платформой будут аккаунты, с которых вы ведете работу. Их есть несколько типов, и каждый из них имеет свою относительную ценность.",
                        "Живые аккаунты Gmail - высокая ценность ввиду долгой истории на платформе, которая дает высокий кредит доверия со стороны сервиса. Самая большая проблема это когда аккаунт уходит в разлог, и система предотвращает повторный доступ к нему.",
                        "Самореги - дешево и сердито, отлично подходит если у вас есть свободное время и надо сэкономить бюджет, но ввиду очень низкого траста из-за пустой истории работать с такими аккаунтами будет несколько сложно.",
                        "Арендованные аккаунты - соблазнительный, но крайне ненадежный вариант ввиду того что легко можно словить бан за нарушение правил площадки.",
                        "Аккаунты с кредитным лимитом - тип аккаунтов, использующий при регистрации кредитные карты для оплаты своей деятельности, в среднем содержат до 350$ и используются до момента исчерпания лимита.",
                    ],
                    title4: "Создание рекламного аккаунта GoogleAds",
                    text4: [
                        "После того как вы определились с выбором аккаунта, осталось сделать еще два шага:",
                        "<span>◆</span> Перейти на страницу <a href='https://ads.google.com/'>https://ads.google.com/</a>",
                        "<span>◆</span> Начать процесс",
                        "Сразу лайфхак для тех, кому проще работать с русским интерфейсом: вне зависимости от вашего аккаунта и локали, язык страницы можно переключить, добавив в адресную строку &hl=ru",
                        "Нажимаем “Перейти в режим эксперта“",
                        /*"<img :src='@/assets/images/articles-img/google-traffic/img1.png' alt='' >",*/
                        "“Создать аккаунт без кампании” - для упрощения процесса",
                        /*"<img src='@/assets/images/articles-img/google-traffic/img2.png' alt='' >",*/
                        "Далее нам предложат выбрать валюту. Имейте в виду, что после этого этапа поменять ее будет невозможно!",
                        "Далее, отмечаем пункт “Нет, я не хочу получать советы по повышению эффективности, специальные предложения, приглашения для участия в опросах и тестировании новых функций”",
                        /*"<img src='@/assets/images/articles-img/google-traffic/img3.png' alt='' >",*/
                        "Успех! На этом процесс создания рекламного аккаунта закончен и можно заняться оформлением кампании!",
                    ],
                    title5: "Работа с прелендом и клоакинг",
                    text5: [
                        "Клоакинг является важным элементом для достижения ЦА и обхода ботов. Очень важно не скупиться на этот момент и использовать домены 1-го уровня или сайты с активной историей, для того чтобы Гугл лояльнее отнесся к вашей платформе. В противном случае, особенно при использовании доменов 2-3 уровня, можно привлечь ненужное внимание и словить бан еще в начале работы.",
                        "Что касается инструментов, то два лучших варианта - это использовать Adspect или Keitaro. Обе утилиты отлично заточены под трафик Гугла и позволяют наладить процесс клоакинга под ваши требования и ситуацию.",
                        "Конечно, применение клоакинга не обязательно, если у вас безобидный оффер, и вы никак не нарушаете правила Гугла, но если уж вам понадобился этот инструмент, то постарайтесь сделать вашу заглавную страницу максимально безобидной, а уж к кнопкам привязать нужное вам поведение.",
                    ],
                    title6: "",
                    text6: [
                        "Как выбрать партнерку",
                        "Выбор партнерской программы - трудоемкая задача, особенно для новичка, но есть основные моменты, которые помогут разобраться с этим вопросом и найти что-то под ваш профиль.",
                        "1. <strong>Проверьте открыта ли у ПП регистрация.</strong>",
                        "Это не такой странный пункт, как можно подумать. Далеко не каждая ПП будет готова взять в работу новичка по ряду причин, начиная с банальной экономии ресурсов, и заканчивая потенциальным репутационным вредом который может нанести малообученный человек. Из-за этого лишь крупные платформы могут открыть свои двери для всех желающих.",
                        "2. <strong>Публичные отзывы.</strong>",
                        "На заборе много чего написано, но не стоит с ходу отмахиваться от подобной информации. Возможно у выбранной вами партнерской программы плохо организована техподдержка, неудобно собранный сайт и плохой дизайн, или есть иные качества, которые вызывают у опытных пользователей беспокойство - все это нужно иметь ввиду дабы самому не попасть в сложную ситуацию, особенно в начале пути. Если у вас есть знакомые специалисты с опытом работы - обязательно обратитесь к ним за их опытом и выслушайте рекомендации.",
                        "3. <strong>Офферы.</strong>",
                        "Стоит учитывать, что далеко не каждая ПП работает со всеми направлениями. Возможно, выбранная вами сфера, не принесла ожидаемых результатов, и вам хочется попробовать себя в чем-то другом, но выбранная ПП не поддерживает ваш вариант - это приведет к тому, что вам вновь придется тратить время и ресурсы на поиски новой платформы, организацию и настройку рабочего процесса, поэтому обязательно смотрите, чтобы либо у ПП имелось как можно больше направлений, либо чтобы представленные тематики удовлетворяли вашим интересам. Для новичков особенно полезно большое кол-во офферов, т.к. они дают возможность не только опробовать себя в различных сферах, но и предоставляют разнообразные уровни сложности, на которых можно научиться работе и отточить навык.",
                    ],
                    title7: "Заключение.",
                    text7: [
                        "Если вы новичок в данной сфере - стоит быть готовым набить немало шишек прежде чем получится поставить процесс на поток. При этом важно не скупиться на ресурсы, особенно на клоакинг, т.к. просчет в этом пункте может сулить провал еще на старте, и много потраченных ресурсов. Однако, набравшись достаточно опыта, потенциал для работы весьма расширяется и дает большое количество возможностей для заработка.",
                    ],
                }
            },
        ],
        ourValues: [
            {
                icon: "ourvalues-people.svg",
                title: "Люди — наш главный актив",
                text: "Мы делаем все, чтобы наш коллектив рос вместе с нами, и каждый чувствовал себя частью единой большой команды. Мы помогаем им добиваться своих жизненных целей, поэтому мы любим амбициозных людей, тех, кто не боится мечтать и ставить перед собой грандиозные задачи.",
                minHeight: '57px'
            },
            {
                icon: "ourvalues-safety.svg",
                title: "Открытость и честность",
                text: "Мы ценим эти качества. Для нас это важнее денег. Мы не просто хотим работать, мы хотим работать со “своими людьми”, близкими нам по духу, связанных с достижением общих целей.",
                minHeight: '57px'
            },
            {
                icon: "ourvalues-chart.svg",
                title: "Work Hard — Play Hard",
                text: "Этот девиз — точно про нас. Мы трудоголики, работаем 24/7, но когда приходит время отдыхать, мы отрываемся по полной со “своими людьми”.",
                minHeight: '57px'
            }            
        ]
    },
    mutations: {
        SET_IS_LOADING(state, payload) {
            state.isLoading = payload
        }
    },
    actions: {
        getIsLoading({commit}, payload) {
            return commit('SET_IS_LOADING', payload)
        }
    },
    getters: {
        getArticles: (state) => state.articles,
        getOurValues: (state) => state.ourValues,
        getSocials(state) {
            return state.socials
        },
        filterSocials(state) {
            return state.socials.filter(social => social.alt !== 'facebook')
        }
    },
    modules: {}
})
