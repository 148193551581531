<template>
  <div class="ModalFormSent">
    <BaseButtonBurger class="ModalFormSent-Close" @click="$emit('hideModal')">
      <img loading="lazy" src="@/assets/images/close.svg"
           alt="" class="Burger-Img">
    </BaseButtonBurger>
    <div class="ModalFormSent-IconBox">
      <BaseIcon :name="'check'" :width="110" :height="110" fill="#2BF12E"/>
    </div>
    <h4 class="Title Title--h4 ModalFormSent-Title" v-html="$t('modal.title')"></h4>
    <p class="Text ModalFormSent-Text" v-html="$t('modal.text')"></p>
    <button type="button" class="Button ModalFormSent-Button" @click="$emit('hideModal')">
           <span class="Button-Text">
              {{ $t('modal.button') }}
            </span>
      <div class="Button-Shadow"></div>
    </button>
  </div>
</template>

<script>
export default {
  name: "ModalFormSent"
}
</script>

<style lang="scss">
.ModalFormSent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 22px 64px;
  text-align: center;
  position: relative;

  @media (min-width: $screen-l) {
    padding: 36px 81px 82px;
  }

  &-IconBox {
    margin-bottom: 34px;
  }

  &-Title {
    margin-bottom: 16px;
  }

  &-Text {
    margin-bottom: 36px;
  }

  &-Button {
    width: 220px;
    height: 60px;

    @media (min-width: $screen-l) {
      width: 208px;
    }
  }

  .Button-Shadow {
    @media (min-width: $screen-l) {
      width: 120px;
      height: 120px;
    }
  }

  &-Close {
    position: absolute;
    top: -50px;
    left: 90%;
    border: 2px solid var(--color-text-main2);

    @media (min-width: $screen-l) {
      left: calc(50% + 350px);
    }

    @media (min-width: $screen-xxl) {
      left: calc(50% + 630px);
    }
  }

}
</style>