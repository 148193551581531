<template>
  <div class="ModalSocials">
    <BaseButtonBurger class="ModalSocials-Close" @click="$emit('hideModal')">
      <img loading="lazy" src="@/assets/images/close.svg"
           alt="close">
    </BaseButtonBurger>
    <h4 class="Title Title--h4">
      подписывайся <br>
      и Узнавай последние<br>
      инсайды, новости и кейсы
    </h4>
    <div class="ModalSocials-Socials">
      <a v-for="(social, index) in socials"
         :key="index"
         :href="social.href"
         target="_blank"
      >
        <img
            :src="require(`@/assets/images/${social.coloredIcon}`)"
            :alt="social.alt"
        >
      </a>
    </div>
    <BaseLogo />
    <div class="ModalSocials-Lighting"></div>
    <LottieAnimation
        v-show="$screen.width > 959"
        class="ModalSocials-SphereImg anim"
        ref="anim"
        :animationData="require('@/assets/animations/circle-animation.json')"
        background="transparent"
        loop
        autoPlay
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LottieAnimation from 'lottie-web-vue';

export default {
  name: "ModalSocials",
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters({
      socials: 'filterSocials'
    })
  }
}
</script>

<style lang="scss">
.ModalSocials {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 77px 0 38px;
  text-align: center;

  &-Close {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    border: 2px solid var(--color-banner-bg);
  }

  h4 {
    z-index: 2;
  }

  &-Socials {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin: 70px 0;

    a + a {
      margin-left: 68px;
    }
  }

  .Logo {
    width: 317px;
  }

  &-SphereImg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: .1;
  }

  &-Lighting {
    position: absolute;
    z-index: 0;
    top: 52%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 200px;
    height: 200px;
    background: linear-gradient(180deg, #2BF12E 0%, rgba(43, 241, 46, 0) 100%);;
    border-radius: 50%;
    box-shadow: 1px 1px 1000px var(--color-text-main2);
    filter: blur(40px);
    opacity: .2;
  }

}
</style>