<template>
  <div class="ModalPromo" name="ModalPromo">
    <BaseButtonBurger class="ModalPromo-Close" @click="hideModalPromo">
      <img loading="lazy" src="@/assets/images/close.svg" alt="close" />
    </BaseButtonBurger>
    <div
      class="ModalPromo-Frontside"
      :class="{ 'ModalPromo--hidden': isBacksideOpened }"
    >
      <h4 class="ModalPromo-Title">Топовый кейс</h4>
      <h3 class="ModalPromo-SubtitleFirst">$182 820</h3>
      <h2 class="ModalPromo-SubtitleSecond">На гемблинге</h2>
      <img src="@/assets/images/tt.png" alt="telegram" />
      <span class="ModalPromo-SubtitleThird">Хочешь его получить?</span>
      <div class="ModalPromo-Telegram">
        Подпишись на наш <br />Telegram
        <span class="tg">@syndicate_gg</span>
      </div>
      <a
        href="https://t.me/syndicate_gg"
        target="_blank"
        class="ModalPromo-Button Button Button--yellow"
        @click="openModalBackSide"
        ><span class="ModalPromo-ButtonText">
          <img src="@/assets/images/tgicon.svg" alt="telegram" />Подписаться
        </span>
      </a>
    </div>
    <div
      class="ModalPromo-Backside"
      :class="{ 'ModalPromo--hidden': !isBacksideOpened }"
    >
      <h4 class="ModalPromo-Title">Спасибо <br />за подписку!</h4>
      <h2 class="ModalPromo-SubtitleSecond">Лови кейс</h2>
      <img src="@/assets/images/tt2.png" alt="tiktok" />
      <h2 class="ModalPromo-SubtitleFourth">от команды</h2>
      <img src="@/assets/images/sg.png" alt="logo" />
      <a
        href="https://teletype.in/@syndicate_group/1gyB0a3kM7W"
        target="_blank"
        class="ModalPromo-Button Button Button--yellow"
        ><span class="ModalPromo-ButtonText">
          Посмотреть
        </span>
      </a>
    </div>
    <div class="BeamBg-1"></div>
    <div class="BeamBg-2"></div>
    <div class="SalutBg-1"></div>
    <div class="SalutBg-2"></div>
  </div>
</template>

<script>
export default {
  name: "ModalPromo",
  data() {
    return {
      isBacksideOpened: false
    };
  },
  methods: {
    openModalBackSide () {
        this.isBacksideOpened = true
    },
    hideModalPromo() {
        this.$emit('hide-modal-promo')
    }
  }
};
</script>

<style lang="scss">
.ModalPromo {
  position: relative;
  padding: 47px 0 38px;
  text-align: center;
  background: linear-gradient(
      135deg,
      rgba(157, 0, 255, 0.58),
      rgba(0, 0, 0, 0) 40%
    ),
    linear-gradient(315deg, rgba(49, 228, 57, 0.47), rgb(0, 0, 0) 40%);
  border: 4px solid;
  border-image-source: linear-gradient(180deg, #2bf12e 0%, #fefc38 100%);
  border-image-slice: 1;
  font-family: "JetBrainsMono";
  pointer-events: none;

  &--hidden {
    display: none !important;
  }

  &-Frontside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Backside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Title {
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    padding: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  &-SubtitleFirst {
    font-weight: 700;
    font-size: 50px;
    line-height: 66px;
    color: #2bf12e;
  }

  &-SubtitleSecond {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    text-transform: uppercase;
    color: #2bf12e;
    padding: 5px;
  }

  &-SubtitleFourth {
    font-weight: 400;
    font-size: 28px;
    line-height: 37px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
  }

  &-SubtitleThird {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #fefc38;
    padding: 15px;
  }

  &-Telegram {
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    color: #ffffff;

    span {
      color: #2bf12e;
    }
  }

  &-Button {
    margin: auto;
    margin-top: 15px;
    height: 50px;
    min-width: 176px;
    pointer-events: auto;

    span {
      display: flex;
      align-items: center;
    }
    img {
      height: 22px;
      width: 26px;
      margin-right: 7px;
    }
  }

  .BeamBg-1 {
    position: absolute;
    bottom: 35px;
    right: 0;
    height: 74px;
    width: 378px;
    background: url("../assets/images/dots.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .BeamBg-2 {
    position: absolute;
    top: 15px;
    left: 0;
    height: 74px;
    width: 378px;
    background: url("../assets/images/dots.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .SalutBg-1 {
    position: absolute;
    bottom: 155px;
    left: 0;
    height: 86px;
    width: 86px;
    background: url("../assets/images/salut1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
  }

  .SalutBg-2 {
    position: absolute;
    top: 85px;
    right: 0;
    height: 112px;
    width: 112px;
    background: url("../assets/images/salut2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }

  &-Close {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    border: 2px solid var(--color-banner-bg);
    pointer-events: auto;
  }
}
</style>
